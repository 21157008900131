import React,{Suspense, lazy, useEffect} from 'react';
import Navbar from './Navbar';
import Slider from './Slider';
import ReactGA from "react-ga";
import HashLoader from "react-spinners/HashLoader";
const ContactForm =lazy(()=>import('./ContactForm'));
const Footer =lazy(()=>import('./Footer'));
const Products = lazy(()=>import ('./Products'));

export default function Home() {
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  },[])

  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <div>
      <Navbar/>
      
      <Slider/>
      <Suspense fallback={<div className="center">
            <HashLoader
              color={"#cf36d6"}
              loading={true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>}>
      <Products/>
      </Suspense>
      <Suspense fallback={<div className="center">
            <HashLoader
              color={"#cf36d6"}
              loading={true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>}>
      <ContactForm/>
      </Suspense>
      <Suspense fallback={<div className="center">
            <HashLoader
              color={"#cf36d6"}
              loading={true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>}>
      <Footer/>
      </Suspense>
    </div>
  )
}
