import React, { useEffect } from "react";
import Navbar from "./Navbar";
import useFetch from "../hooks/useFetch";
import { useParams, Link } from "react-router-dom";
import Footer from "./Footer";
import HashLoader from "react-spinners/HashLoader";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  let { loading, error, data } = useFetch(
    `https://monkfish-app-rtjqb.ondigitalocean.app/api/products?filters[slug]=${id}&populate=*`
  );

  if (loading)
    return (
      <div className="center">
        <HashLoader
          color={"#cf36d6"}
          loading={loading}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  if (error) return <p>Error...</p>;

  return (
    <div className="forbackground">
      <Navbar />
      <div className="spacinghere"></div>
      <div className="producttitle">
        <h1 className="underborder">{data[0].attributes.title}</h1>

        <p>#Brand: {data[0].attributes.Brand}</p>
      </div>
      <div className="card mb-3">
        <img
          src={data[0].attributes.image.data.attributes.url}
          className="card-img-top"
          alt={data[0].attributes.image.data.attributes.alternativeText}
        />
        <div className="card-body">
          <h5 className="card-title">
            <Link to="/contact">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <i class="fa-regular fa-hand-pointer"></i>Book Demo
            </Link>
          </h5>
          <div className="description-background">
            <p className="card-text boro">Features</p>
            <p className="card-text">
              <small className="text-muted">
                {data[0].attributes.description}
              </small>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
