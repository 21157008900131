import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import ReactGA from "react-ga";
import Navbar from "./Navbar";
import Slider from "./Slider";
import HashLoader from "react-spinners/HashLoader";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

export default function Items(props) {
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname);
  },[])
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  const [url, setUrl] = useState(
    `https://monkfish-app-rtjqb.ondigitalocean.app/api/products?${props.category}&populate=*`
  );

  let { loading, error, data } = useFetch(url);

  function category(name) {
    setUrl(
      `https://monkfish-app-rtjqb.ondigitalocean.app/api/products?filters[category]=${name}&populate=*`
    );
  }

  const All = (e) => {
    e.preventDefault();
    setUrl(
      `https://monkfish-app-rtjqb.ondigitalocean.app/api/products?populate=*`
    );
  };
  let navigate = useNavigate();
  function Navi(id) {
    navigate(`/product/${id}`);
  }

  if (error) return <p>Error...</p>;

  return (
    <div>
      <Navbar />

      <Slider />
      <div className="propage">
        <h1>Our Featured Products</h1>
        <p>
          Select any Category | Click on Product and Book Demo
        </p>
        <div className="filter">
          <ul>
            <li>
              <button
                onClick={All}
                className={url.includes("filters") ? "" : "hoverme"}
              >
                All
              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("chimney");
                }}
                className={url.includes("chimney") ? "hoverme" : ""}
              >
                Chimney
              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("aquaguard");
                }}
                className={url.includes("aquaguard") ? "hoverme" : ""}
              >
                Water Purifier
              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("vacuum cleaner");
                }}
                className={url.includes("vacuum cleaner") ? "hoverme" : ""}
              >
                Vacuum Cleaner
              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("geyser");
                }}
                className={url.includes("geyser") ? "hoverme" : ""}
              >
                Geyser
              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("induction");
                }}
                className={url.includes("induction") ? "hoverme" : ""}
              >
                Induction
              </button>
            </li>
            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("Hobs");
                }}
                className={url.includes("Hobs") ? "hoverme" : ""}
              >
                CookTops & Hobs
              </button>
            </li>

            <li>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  category("microoven");
                }}
                className={url.includes("microoven") ? "hoverme" : ""}
              >
                MicroOven
              </button>
            </li>
          </ul>
        </div>

        {loading ? (
          <div className="center">
            <HashLoader
              color={"#cf36d6"}
              loading={loading}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="propage1">
            {data.map((item) => (
              <div
                className="collection"
                key={item.id}
                onClick={() => {
                  Navi(item.attributes.slug);
                }}
              >
                <img
                  src={item.attributes.image.data.attributes.url}
                  alt={item.attributes.image.data.attributes.alternativeText}
                />
                <p id="letterup">{item.attributes.title}</p>
                <p id="letterspace">{item.attributes.Brand}</p>
                <p id="click-here">
                  <i class="fa-regular fa-hand-pointer"></i>Click To View!
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
}

Items.defaultProps = {
  category: "",
};
