import Home from './components/Home';
import ReactGA from "react-ga";

const TRACKING_ID = "G-JN0C37NGS9";
ReactGA.initialize(TRACKING_ID);


function App() {

  return (
    <>
    <Home/>
    </>
  );
}

export default App;
