import React from "react";
import Typewriter from "typewriter-effect";

export default function Slider() {
  return (
    <div id="carouselExampleCaptions" className="carousel slide">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="5"
          aria-label="Slide 6"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src="./images/modern kitchen image 4.jpg"
            className="d-block w-100"
            alt="1"
          />
          <div className="carousel-caption ">
            <h5>Best Kitchen Appliances</h5>
            <span>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    `"Design your Kitchen with our most Featured Products."`,
                  ],
                }}
              />
            </span>
          </div>
        </div>
        <div className="carousel-item">
          <img src="./images/gas oven.jpg" className="d-block w-100" alt="2" />
          <div className="carousel-caption ">
            <h5>Look Modern</h5>
            <span>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [`"Cook in the Most Stylish Way."`],
                }}
              />
            </span>
          </div>
        </div>

        <div className="carousel-item">
          <img
            src="./images/kitchen chimney 6.jpg"
            className="d-block w-100"
            alt="3"
          />
          <div className="carousel-caption ">
            <h5>Enhance your Comfort Zone</h5>
            <span>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    `"Let the Chimney Take Care of your Kitchen Health."`,
                  ],
                }}
              />
            </span>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="./images/vaccum cleaner robot 2.jpg"
            className="d-block w-100"
            alt="3"
          />
          <div className="carousel-caption ">
            <h5>Clean at Ease</h5>
            <span>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    `"Its okay to be Lazy about Cleaning while our Robot do it for you."`,
                  ],
                }}
              />
            </span>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="./images/modern kitchen image 3.jpg"
            className="d-block w-100"
            alt="3"
          />
          <div className="carousel-caption ">
            <h5>Seasoning the Kitchen with Love</h5>
            <span>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [`"Recreate the Best of You."`],
                }}
              />
            </span>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="./images/induction image 6.jpg"
            className="d-block w-100"
            alt="3"
          />
          <div className="carousel-caption ">
            <h5>Dont let Anything Stop You</h5>
            <span>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [`"Become the Modern Chef."`],
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
