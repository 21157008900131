import React, { Suspense, lazy, useEffect } from "react";
import Navbar from "./Navbar";
import HashLoader from "react-spinners/HashLoader";
const ContactForm =lazy(()=>import('./ContactForm'));
const Footer = lazy(() => import("./Footer"));

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Navbar />
      <div className="contactimg"></div>

      <div className="box-space3">
        <h3 className="box-space2">Contact Us</h3>
      </div>
      <Suspense fallback={<div className="center">
            <HashLoader
              color={"#cf36d6"}
              loading={true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>}>
      <ContactForm/>
      </Suspense>
      <Suspense fallback={<div className="center">
            <HashLoader
              color={"#cf36d6"}
              loading= {true}
              size={50}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>}>
        <Footer />
      </Suspense>
    </>
  );
}
