import React from "react";
import { Link } from "react-router-dom";

import ScrollToTop from "react-scroll-to-top";

export default function Footer() {
  return (
    <>
      <div className="space"></div>
      <div className="rajFooter">
        <div className="FootContent">
          <div className="detaillist">
            <h3>Office</h3>
            <ul>
              <li>
                <span>
                  <i class="fa-solid fa-user"></i>
                </span>
                <span>Soumyendu Poddar</span>
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <span id="line">soumyendu.spoddar@rediffmail.com</span>
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-phone"></i>
                </span>
                <span>83888 51398</span>
              </li>
            </ul>
          </div>
          <div className="detaillist" id="tobehiden">
            <h3>Links</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <Link to="/products">Products</Link>
              </li>

              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="detaillist">
            <h3>Website Creator</h3>
            <ul>
              <li>
                <span>
                <i class="fa-solid fa-user"></i>
                </span>
                <span>Sourik Poddar</span>
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-envelope"></i>
                </span>
                <span id="line">sourikpoddar896@gmail.com</span>
              </li>
              <li>
                <span>
                  <i className="fa-solid fa-phone"></i>
                </span>
                <span>62897 67026</span>
              </li>
              <li>
                <p>Contact For Web Development</p>
              </li>
            </ul>
          </div>
        </div>
        <p id="copy">&#169; Copyright. All Rights are Reserved 2023</p>
        <ScrollToTop smooth />
        <section>
          <div className="wave wave1"></div>
          <div className="wave wave2"></div>
          <div className="wave wave3"></div>
          <div className="wave wave4"></div>
        </section>
      </div>
    </>
  );
}
