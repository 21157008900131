import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import About from './components/About';
import ContactUs from './components/ContactUs';
import Errorhandle from './components/Errorhandle';
import Items from './components/Items';



const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Errorhandle />,
  },
  {
    path: "/product/:id",
    element: <About/>,
    errorElement: <Errorhandle />,
  },
  
  {
    path: "/contact",
    element: <ContactUs/>,
    errorElement: <Errorhandle />,
  },
  
  {
    path: "/products",
    element: <Items/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/chimney",
    element: <Items category="filters[category]=chimney"/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/aquaguard",
    element: <Items category="filters[category]=aquaguard"/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/vacuumcleaner",
    element: <Items category="filters[category]=vacuum cleaner"/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/geyser",
    element: <Items category="filters[category]=geyser"/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/hobs",
    element: <Items category="filters[category]=Hobs"/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/microoven",
    element: <Items category="filters[category]=microoven"/>,
    errorElement: <Errorhandle />,
  },
  {
    path: "/induction",
    element: <Items category="filters[category]=induction"/>,
    errorElement: <Errorhandle />,
  },

]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


