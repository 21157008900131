import React from "react";
import { Link, useLocation } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { useState } from "react";
import useFetch from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

export default function Navbar() {
  let { loading, error, data } = useFetch(`https://monkfish-app-rtjqb.ondigitalocean.app/api/products`);
  let location = useLocation();
  let [value, setValue] = useState("");
  let [menu,setMenu] = useState(true);
  const onChange = (event) => {
    setValue(event.target.value);
  };

  let navigate = useNavigate();
  const onSearch = (searchTerm) => {
    if (searchTerm === "") {
      return;
    } else {
      searchTerm = searchTerm.split(" ").join("");
      searchTerm = searchTerm.toUpperCase();
      navigate(`/product/${searchTerm}`);
    }
  };

  const Switch1 = ()=>{
    setMenu(false);
  }
  const Switch2 = ()=>{
    setMenu(true);
  }
  if (loading)
    return (
      <div className="center">
        <HashLoader
          color={"#cf36d6"}
          loading={loading}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  if (error) return <p>Error...</p>;
  return (
    <div>
      <div className="raj-navbar">
        <ul className={menu?"list":"menulist"}>
          <li className={menu?"items":"menuitems"}>
            <Link
              to="/"
              className={`nav-${location.pathname === "/" ? "action" : ""}`}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>Home
            </Link>
          </li>

          <li className={menu?"items":"menuitems"}>
            <Link
              to="/products"
              className={`nav-${
                location.pathname === "/products" ? "action" : ""
              }`}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>Products
            </Link>
          </li>
          <li className={menu?"sp-items":"items"} id="comname">
            <Link to="/" id="rajapp">
              Raj Appliances
            </Link>
          </li>

          <li className={menu?"items":"menuitems"}>
            <Link
              to="/contact"
              className={`nav-${
                location.pathname === "/contact" ? "action" : ""
              }`}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>Contact Us
            </Link>
          </li>

          <li className={menu?"items":"menuitems"}>
            <input id="searchtext" type="text" placeholder="Search..." value={value} onChange={onChange} />
            <i
              onClick={() => onSearch(value)}
              className="fa-solid fa-magnifying-glass"
            ></i>
          </li>
          {data
            .filter((item) => {
              const searchTerm = value.toLowerCase();
              const fullName = item.attributes.title.toLowerCase();
              return searchTerm && fullName.startsWith(searchTerm);
            })
            .slice(0, 1)
            .map((item) => (
              <li
                key={item.id}
                onClick={() => onSearch(item.attributes.title)}
                className={value !== "" ? "searchlist" : "hidden"}
              >
                {item.attributes.title}
              </li>
            ))}

            <li className="Drop1">
            <div className={menu?"":"Drop"}>
            <i onClick={Switch1} className="fa-solid fa-bars"></i>
            </div>
            <div className={menu?"Drop":""}>
            <i onClick={Switch2} className="fa-solid fa-xmark"></i>
            </div>
            </li>

        </ul>
      </div>
    </div>
  );
}
