import React from "react";
import erroranimation from "../erroranimation.json";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

export default function Errorhandle() {
  return (
    <div>
      <div className="errorflex">
        <div className="errorhandle">
          <Lottie animationData={erroranimation} />
        </div>
        <div className="error2">
          <h1>Error</h1>
          <Link to="/">Click To Go Back</Link>
          <p>Contact Us for Product Details</p>
          <i class="fa-solid fa-arrow-left"></i>
        </div>
      </div>
    </div>
  );
}
